import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const TopicManagement = () => {
  const [topics, setTopics] = useState([]);
  const [topicName, setTopicName] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [searchLanguage, setSearchLanguage] = useState('');
  const [contentLanguage, setContentLanguage] = useState('german');
  const [message, setMessage] = useState('');
  const [editState, setEditState] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedbackMessages, setFeedbackMessages] = useState({});

  const fetchTopics = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/topic/get-all`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }
      const data = await response.json();
      data.sort((a, b) => a.id - b.id);
      setTopics(data);
    } catch (error) {
      console.error('Error fetching topics:', error);
      setMessage('Failed to fetch topics');
    }
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!topicName || !searchLanguage) {
      setMessage('Please fill in all fields');
      return;
    }

    setLoading(true);
    const response = await fetch(`${config.apiUrl}/topic/create`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: topicName, thumbnail_url: thumbnailUrl, search_language: searchLanguage, content_language: contentLanguage }),
    });

    setLoading(false);
    if (response.ok) {
      setMessage('Topic created successfully');
      setTopicName('');
      setThumbnailUrl('');
      setSearchLanguage('');
      fetchTopics();
    } else {
      const errorMessage = await response.text();
      setMessage(`Failed to create topic: ${errorMessage}`);
    }
  };

  const handleEditChange = (topicId, field, value) => {
    setEditState(prev => ({
      ...prev,
      [topicId]: {
        ...prev[topicId],
        [field]: value,
      },
    }));
  };

  const handleUpdate = async (topicId) => {
    setLoading(true);
    const topicToUpdate = editState[topicId];
    const response = await fetch(`${config.apiUrl}/topic/update`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: topicId, ...topicToUpdate }),
    });

    setLoading(false);
    if (response.ok) {
      setFeedbackMessages(prev => ({ ...prev, [topicId]: 'Update successful' }));
      setEditState(prev => {
        const newState = { ...prev };
        delete newState[topicId];
        return newState;
      });
      fetchTopics();
    } else {
      const errorMessage = await response.text();
      setFeedbackMessages(prev => ({ ...prev, [topicId]: `Failed to update: ${errorMessage}` }));
    }
  };

  const handleAggregateClick = async (topicName) => {
    setLoading(true);
    setFeedbackMessages(prev => ({ ...prev, [topicName]: '' }));
    const encodedTopicName = encodeURIComponent(topicName).toLowerCase();
    setFeedbackMessages(prev => ({ ...prev, [topicName]: 'Aggregating...' }));
    const response = await fetch(`${config.apiUrl}/aggregate-articles/${encodedTopicName}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setFeedbackMessages(prev => ({ ...prev, [topicName]: 'Aggregation successful' }));
    } else {
      const errorMessage = await response.text();
      setFeedbackMessages(prev => ({ ...prev, [topicName]: `Failed to aggregate: ${errorMessage}` }));
    }
    setLoading(false);
    fetchTopics();
  };

  return (
    <div className="max-w-6xl mx-auto p-4 bg-gray-100">
      <Link className="text-blue-600 hover:text-blue-800 underline mb-4 inline-block" to="/admin">Back to Admin Dashboard</Link>

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Create a New Topic</h2>
        {message && <p className="text-sm font-medium text-red-600 mb-4">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="topicName" className="block text-sm font-medium text-gray-700">Topic Name:</label>
            <input
              id="topicName"
              type="text"
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="thumbnailUrl" className="block text-sm font-medium text-gray-700">Thumbnail URL:</label>
            <input
              id="thumbnailUrl"
              type="text"
              value={thumbnailUrl}
              onChange={(e) => setThumbnailUrl(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="locale" className="block text-sm font-medium text-gray-700">Locale:</label>
            <select
              id="locale"
              value={searchLanguage}
              onChange={(e) => setSearchLanguage(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select Locale</option>
              <option value="en-US">English (United States)</option>
              <option value="de-AT">German (Austria)</option>
            </select>
          </div>
          <button 
            type="submit" 
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Create Topic
          </button>
        </form>
      </div>

      <h2 className="text-2xl font-bold mb-4 text-gray-800">Manage Topics</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Name</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Language</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Thumbnail</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Last Aggregated</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Active</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {topics.map((topic) => (
              <tr key={topic.id}>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{topic.id}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={editState[topic.id]?.name || topic.name}
                    onChange={(e) => handleEditChange(topic.id, 'name', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={editState[topic.id]?.language_code || topic.language_code}
                    onChange={(e) => handleEditChange(topic.id, 'language_code', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={editState[topic.id]?.thumbnail_url || topic.thumbnail_url}
                    onChange={(e) => handleEditChange(topic.id, 'thumbnail_url', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{topic.last_aggregated}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={editState[topic.id]?.active ?? topic.active}
                    onChange={(e) => handleEditChange(topic.id, 'active', e.target.checked)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <button 
                    onClick={() => handleUpdate(topic.id)} 
                    disabled={loading}
                    className="mr-2 px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Update
                  </button>
                  <button 
                    onClick={() => handleAggregateClick(topic.name)} 
                    disabled={loading}
                    className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Aggregate
                  </button>
                  <span className="ml-2 text-xs text-gray-600">{feedbackMessages[topic.id] || feedbackMessages[topic.name]}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopicManagement;