import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const Heatmap = ({ similarityMatrix, labels }) => {
  const ref = useRef();
  const [tooltip, setTooltip] = useState({ show: false, x: 0, y: 0, content: '' });

  useEffect(() => {
    if (!similarityMatrix || similarityMatrix.length === 0) return;

    const margin = { top: 50, right: 150, bottom: 150, left: 150 };
    const width = 1000 - margin.left - margin.right;
    const height = 1000 - margin.top - margin.bottom;

    d3.select(ref.current).selectAll("*").remove();

    const svg = d3.select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .range([0, width])
      .domain(d3.range(similarityMatrix.length))
      .padding(0.05);

    const y = d3.scaleBand()
      .range([height, 0])
      .domain(d3.range(similarityMatrix.length))
      .padding(0.05);

    const colorScale = d3.scaleSequential(d3.interpolateBlues)
      .domain([0, 1]);

    // Function to truncate text
    const truncateText = (text, maxLength) => {
      return text.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
    };

    // Create heatmap cells
    svg.selectAll()
      .data(similarityMatrix.flatMap((row, i) => row.map((value, j) => ({ i, j, value }))))
      .enter()
      .append("rect")
      .attr("x", d => x(d.i))
      .attr("y", d => y(d.j))
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("fill", d => colorScale(d.value))
      .style("stroke", "white")
      .style("stroke-width", 0.5)
      .on("mousemove", (event, d) => {
        const [mouseX, mouseY] = d3.pointer(event, svg.node());
        const tooltipContent = `X: ${labels[d.i]}<br/>Y: ${labels[d.j]}<br/>Value: ${d.value.toFixed(2)}`;
        setTooltip({
          show: true,
          x: mouseX + margin.left,
          y: mouseY + margin.top,
          content: tooltipContent
        });
      })
      .on("mouseout", () => {
        setTooltip({ ...tooltip, show: false });
      });

    // Add x-axis labels
    svg.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat(i => ""))
      .selectAll("text")
      .remove();

    svg.selectAll(".x-label")
      .data(labels)
      .enter()
      .append("text")
      .attr("class", "x-label")
      .attr("x", (d, i) => x(i) + x.bandwidth() / 2)
      .attr("y", height + 10)
      .attr("transform", (d, i) => `rotate(45, ${x(i) + x.bandwidth() / 2}, ${height + 10})`)
      .style("text-anchor", "start")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .text(d => truncateText(d, 20));

    // Add y-axis labels
    svg.append("g")
      .call(d3.axisLeft(y).tickFormat(i => ""))
      .selectAll("text")
      .remove();

    svg.selectAll(".y-label")
      .data(labels)
      .enter()
      .append("text")
      .attr("class", "y-label")
      .attr("x", -10)
      .attr("y", (d, i) => y(i) + y.bandwidth() / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "end")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .text(d => truncateText(d, 20));

    // Add title
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", -20)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .text("Embedding Similarity Heatmap");

  }, [similarityMatrix, labels]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div ref={ref}></div>
      {tooltip.show && (
        <div
          style={{
            position: 'absolute',
            top: `${tooltip.y}px`,
            left: `${tooltip.x}px`,
            backgroundColor: 'white',
            border: '1px solid black',
            padding: '5px',
            borderRadius: '5px',
            pointerEvents: 'none',
            zIndex: 1000,
            transform: 'translate(10px, -50%)'
          }}
          dangerouslySetInnerHTML={{ __html: tooltip.content }}
        />
      )}
    </div>
  );
};

export default Heatmap;