import React from 'react';
import ArticleCard from './ArticleCard';

const ClickableArticleCard = ({ article, onClick, isSelected }) => {
  return (
    <div 
      onClick={() => onClick(article)} 
      className={`cursor-pointer hover:shadow-lg transition-shadow duration-300 ${isSelected ? 'border-2 border-blue-500' : ''}`}
    >
      <ArticleCard article={article} />
      
    </div>
  );
};


export { ClickableArticleCard };