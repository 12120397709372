import React, { useContext, useState, useEffect } from 'react';
import { Route, Routes, Link, NavLink, useNavigate, useLocation } from 'react-router-dom'; // import useLocation
import './App.css';
import NewsArticles from './feed/NewsArticles';
import TopicOverviewComponent from './news-hub/TopicOverviewComponent';
import ContentOverviewComponent from './content-hub/ContentOverviewComponent';
import RegistrationForm from './RegistrationForm';
import LoginForm from './LoginForm';
import AdminProfileComponent from './profile/admin/AdminProfileComponent';
import AdminDashboard from './profile/admin/AdminDashboard';
import TopicManagement from './profile/admin/TopicManagement';
import ContentTypeManagement from './profile/admin/ContentTypeManagement';
import UserRoleManagement from './profile/admin/UserRoleManagement';
import CouponManagement from './profile/admin/CouponManagement';
import PromptComparisonInterface from './profile/admin/llm/PromptComparisonInterface';
import SimilarityDashboard from './profile/admin/llm/SimilarityDashboard';

import UserProfileComponent from './profile/UserProfileComponent';
import CustomFeedComponent from './feed/CustomFeed';
import Footer from './FooterComponent';
import LandingPage from './landing/LandingPage';

import { UserContext } from './AuthProvider';

import logo from './images/Logo.png'

import config from './config';
import Contact from './ContactComponent';
import ContentHubWrapper from './content-hub/ContentHubWrapper';

function App() {
  const [isTopicDropdownVisible, setIsTopicDropdownVisible] = useState(false);
  const [isContentDropdownVisible, setIsContentDropdownVisible] = useState(false);

  const [topics, setTopics] = useState([]);
  const [contentTypes, setContentTypess] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, serviceAccessUpdated } = useContext(UserContext);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/topic/get-all-active`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to fetch topics');
        const data = await response.json();
        setTopics(data);
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    const fetchContentTypes = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/content/get-all-active`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to fetch content types');
        const data = await response.json();
        setContentTypess(data);
      } catch (error) {
        console.error('Error fetching content types:', error);
      }
    };

    fetchContentTypes();
    fetchTopics();
  }, [user, serviceAccessUpdated]);


  const handleTopicChange = (topicName) => {
    setIsTopicDropdownVisible(false);
    const encodedTopic = encodeURIComponent(topicName).toLowerCase();
    navigate(`/news-hub/${encodedTopic}`);
  };

  const handleContentChange = (contentName) => {
    setIsContentDropdownVisible(false);
    const encodedContent = encodeURIComponent(contentName).toLowerCase();
    navigate(`/content-hub/${encodedContent}`);
  };

  // Check if the current path starts with /news-hub/
  const checkIsNewsHubActive = () => {
    return location.pathname.startsWith('/news-hub');
  };

  const checkIsContentHubActive = () => {
    return location.pathname.startsWith('/content-hub');
  };

  return (
    <div className="site-container">
      <div className="content-wrap">
        <div className="header-section">
          <div className="header-container">
            <Link to="/landing">
              <img src={logo} alt="AI INFO HUB Logo" className="logo" />
            </Link>
            <div className="header-links-container">
              {user && ( // Conditional rendering based on if the user exists
                <>
                  <NavLink to="/custom-feed" className={({ isActive }) => isActive ? 'active-link' : undefined}>
                    Home
                  </NavLink>
                  <div className="feed-link-container"
                      onMouseEnter={() => setIsContentDropdownVisible(true)}
                      onMouseLeave={() => setIsContentDropdownVisible(false)}
                  >
                    <NavLink to="/content-hub" className={({ isActive }) => checkIsContentHubActive() ? 'active-link' : undefined} >
                      Content Hub
                    </NavLink>
                    {isContentDropdownVisible && (
                      <div className="dropdown-menu">
                        {contentTypes.map((topic, index) => (
                          <div key={index} onClick={() => handleContentChange(topic.name)}>
                            {topic.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="feed-link-container"
                      onMouseEnter={() => setIsTopicDropdownVisible(true)}
                      onMouseLeave={() => setIsTopicDropdownVisible(false)}
                  >
                    <NavLink to="/news-hub" className={({ isActive }) => checkIsNewsHubActive() ? 'active-link' : undefined} >
                      News Hub
                    </NavLink>
                    {isTopicDropdownVisible && (
                      <div className="dropdown-menu">
                        {topics.map((topic, index) => (
                          <div key={index} onClick={() => handleTopicChange(topic.name)}>
                            {topic.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                </>
              )}
              {user ? (
                  <>
                    {user?.roles?.includes('admin') ? (
                      <NavLink to="/admin" className={({ isActive }) => isActive ? 'active-link' : undefined}>
                        {user.user_name}
                      </NavLink>
                    ) : (
                      <NavLink to="/profile" className={({ isActive }) => isActive ? 'active-link' : undefined}>
                        {user.user_name}
                      </NavLink>
                    )}
                  </>
              ) : (
                  <NavLink id="login" to="/login" className={({ isActive }) => isActive ? 'active-link login' : 'login'}>
                    Einloggen
                  </NavLink>
              )}              
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={
            user ? (
              <CustomFeedComponent />
            ) : (
              <LandingPage />
            )
          } />
          <Route path="/news-hub/:topic" element={<NewsArticles />} />
          <Route path="/news-hub" element={<TopicOverviewComponent topics={topics} />} />
          <Route path="/content-hub/:content-type" element={<ContentHubWrapper topics={topics} />} />
          <Route path="/content-hub" element={<ContentOverviewComponent contentTypes={contentTypes} />} />
          <Route path="/custom-feed" element={<CustomFeedComponent />} />
          <Route path="/registration" element={<RegistrationForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/profile" element={<UserProfileComponent />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/landing" element={<LandingPage />} />

          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/topics" element={<TopicManagement />} />
          <Route path="/admin/content-types" element={<ContentTypeManagement />} />
          <Route path="/admin/users-roles" element={<UserRoleManagement />} />
          <Route path="/admin/coupons" element={<CouponManagement />} />
          <Route path="/admin/prompt-comparison" element={<PromptComparisonInterface />} />
          <Route path="/admin/similarity" element={<SimilarityDashboard topics={topics} />} />

        </Routes>
      </div>
      <Footer />

    </div>
);

}

export default App;
