import React from 'react';

const MultiStepProgressBar = ({ currentStep, totalSteps = 3 }) => {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <div className="w-full max-w-xl mx-auto blurry-bg">
      <div className="flex items-center justify-between mb-4">
        {steps.map((step) => (
          <React.Fragment key={step}>
            <div className="flex flex-col items-center">
              <div
                className={`w-10 h-10 rounded-full flex items-center justify-center border-2 ${
                  step <= currentStep
                    ? 'bg-custom-dark-purple border-custom-dark-purple text-white'
                    : 'bg-custom-purple border-custom-purple text-black-300'
                }`}
              >
                {step}
              </div>
            </div>
            {step < totalSteps && (
              <div
                className={`flex-1 h-1 ${
                  step < currentStep ? 'bg-custom-dark-purple' : 'bg-custom-purple'
                }`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MultiStepProgressBar;