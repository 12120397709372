import { Link } from 'react-router-dom';
import './TopicOverviewStyles.css';

const TopicOverview = ({ topics }) => {

  console.log("in topic overview, topics: ", topics)

  return (
    <div className="landing-page-container">
      <div className="section-content">
        {topics.map((topic, index) => (
          <div key={index} className={'materialCard'} style={{ backgroundImage: `url(${topic.thumbnail_url})` }}>
            <Link to={`/news-hub/${encodeURIComponent(topic.name).toLowerCase()}`}>
              <div className="materialCard-content">
                <h3>{topic.name}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopicOverview;
