import React, { useState, useEffect, useRef } from 'react';
import config from '../../../config';

const PromptTesting = ({ id, onRemove }) => {
  const [provider, setProvider] = useState('openai');
  const [model, setModel] = useState('gpt-3.5-turbo');
  const [systemMessage, setSystemMessage] = useState('');
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const responseRef = useRef(null);

  useEffect(() => {
    if (responseRef.current) {
      responseRef.current.scrollTop = responseRef.current.scrollHeight;
    }
  }, [response]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResponse('');

    try {
      await handleStreamingSubmit();
    } catch (error) {
      console.error('Error testing prompt:', error);
      setError('Failed to test prompt. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStreamingSubmit = async () => {
    const response = await fetch(`${config.apiUrl}/test-prompt`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        system: systemMessage,
        prompt: prompt,
        model: model,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to test prompt');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let accumulatedResponse = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('|END|');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') break;
          accumulatedResponse += data;
          setResponse(accumulatedResponse);
        }
      }
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-800">Prompt Test #{id + 1}</h3>
        <button
          onClick={() => onRemove(id)}
          className="text-red-600 hover:text-red-800 focus:outline-none"
        >
          Remove
        </button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor={`provider-${id}`} className="block text-sm font-medium text-gray-700">LLM API Provider:</label>
          <select
            id={`provider-${id}`}
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
            className="font-bold mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            disabled
          >
            <option value="openai">OpenAI</option>
          </select>
        </div>

        <div>
          <label htmlFor={`model-${id}`} className="block text-sm font-medium text-gray-700">Model:</label>
          <select
            id={`model-${id}`}
            value={model}
            onChange={(e) => setModel(e.target.value)}
            className="font-bold mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
            <option value="gpt-4-turbo">GPT-4 Turbo</option>
            <option value="gpt-4o">GPT-4o</option>
          </select>
        </div>

        <div>
          <label htmlFor={`systemMessage-${id}`} className="block text-sm font-medium text-gray-700">System Message:</label>
          <textarea
            id={`systemMessage-${id}`}
            value={systemMessage}
            onChange={(e) => setSystemMessage(e.target.value)}
            rows={3}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor={`prompt-${id}`} className="block text-sm font-medium text-gray-700">Prompt:</label>
          <textarea
            id={`prompt-${id}`}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            rows={3}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {loading ? 'Testing...' : 'Test Prompt'}
        </button>
      </form>

      {error && (
        <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      {response && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          <h4 className="text-md font-semibold mb-2 text-gray-800">Response:</h4>
          <pre ref={responseRef} className="whitespace-pre-wrap max-h-60 overflow-y-auto">
            {response}
          </pre>
        </div>
      )}
    </div>
  );
};

const PromptComparisonInterface = () => {
  const [promptContainers, setPromptContainers] = useState([{ id: 0 }]);

  const addPromptContainer = () => {
    const newId = Math.max(...promptContainers.map(container => container.id)) + 1;
    setPromptContainers([...promptContainers, { id: newId }]);
  };

  const removePromptContainer = (id) => {
    setPromptContainers(promptContainers.filter(container => container.id !== id));
  };

  return (
    <div className="max-w-7xl mx-auto p-4 bg-gray-100">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Prompt Comparison Interface</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {promptContainers.map((container) => (
          <PromptTesting key={container.id} id={container.id} onRemove={removePromptContainer} />
        ))}
      </div>

      <button
        onClick={addPromptContainer}
        className="mt-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      >
        Add Prompt Container
      </button>
    </div>
  );
};

export default PromptComparisonInterface;