import React from 'react';
import '../../App.css';
import '../NewsArticleStyles.css';

const ArticleActions = ({ article, onFlip, onShowCockpit, leftBtnTxt }) => {
  return (
    <div className="row-sm">
      <div className="bottom-line">
        <button onClick={onFlip} className="button-sm">
          {leftBtnTxt}
        </button>
        <span style={{ fontSize: '12px' }}>
          {
            new Date(article.datePublished).toLocaleDateString('en-GB', {
              day: '2-digit', month: '2-digit', year: 'numeric'
            })
          }
        </span>
        <button onClick={onShowCockpit} className="button-sm">Cockpit</button>
      </div>
    </div>
  );
};

export default ArticleActions;