import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../AuthProvider';
import filterIcon from '../images/filter-outline.svg';
import ArticleCard from './components/ArticleCard';
import './CustomFeedStyles.css';

import config from '../config';

const CustomFeed = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [sortCriteria, setSortCriteria] = useState('general');
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [articles, setArticles] = useState([]);
  const [sortedArticles, setSortedArticles] = useState([]);
  const [hasTopics, setHasTopics] = useState(true);
  const navigate = useNavigate();

  const sortOptions = [
    { value: 'general', label: 'Sortieren nach allg. Bewertung' },
    { value: 'disruptiveness', label: 'Sortieren nach Disruption' }
  ];

  const sortArticles = (articles, criteria) => {
    return [...articles].sort((a, b) => {
      if (criteria === 'general') {
        return b.score_general - a.score_general;
      } else if (criteria === 'disruptiveness') {
        return b.score_disruptiveness - a.score_disruptiveness;
      }
      return 0;
    });
  };
  
  const handleSortChange = (value) => {
    setSortCriteria(value);
    setShowSortOptions(false);
  };

  const fetchInterestedTopicsArticles = async () => {
    setLoading(true);
    try {
      const topicsResponse = await fetch(`${config.apiUrl}/user/interested-topics`, {
        credentials: 'include',
      });
      const topicsData = await topicsResponse.json();

      if (topicsData.interested_topics.length === 0) {
        setHasTopics(false);
        setLoading(false);
        return;
      }

      const articlesPromises = topicsData.interested_topics.map(async (topic) => {
        try {
          const response = await fetch(`${config.apiUrl}/get-articles/${topic.name}?date=${new Date(Date.now() - 86400000).toISOString().split('T')[0]}`, {
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            console.log(`No articles found or failed to fetch articles for topic ${topic.name}`);
            return [];
          }
          const articlesData = await response.json();
          return articlesData; // Array of articles for a topic
        } catch (error) {
          // Log the error and return an empty array to ensure the promise resolves successfully
          console.error(`Error fetching articles for topic ${topic.name}:`, error);
          return [];
        }
      });
  
      // Await all promises and flatten the results into a single array of articles
      const allArticles = (await Promise.all(articlesPromises)).flat();
      setArticles(allArticles);
    } catch (error) {
      console.error('Failed to fetch articles:', error);
    } finally {
      setLoading(false);
    }
  };

  
  const toggleSortOptions = () => {
    setShowSortOptions(prevState => !prevState);
  };

  useEffect(() => {
    if (articles.length) {
      setSortedArticles(sortArticles(articles, sortCriteria));
    }
  }, [articles, sortCriteria]);

  useEffect(() => {
    if (user) {
      fetchInterestedTopicsArticles();
    }
  }, [user]); // Fetch articles when the component mounts and when the user object changes


  if (loading) {
    return <div></div>;
  }
  
  if (!hasTopics || articles.length === 0) {
    return (
      <div className="content-section no-articles">
        <p>
          Es wurden keine Themen ausgewählt oder es gibt keine Artikel zu Deinen Themen. Du kannst für Dich interessante Themen in Deinem Profil auswählen.
        </p>
        <button className="navigate-profile-btn" onClick={() => navigate('/profile')}>
          Zum Profil
        </button>
      </div>
    );
  }

  return (
    <div className="content-section">
      <div className="topic-header-container">
        <h2 className="topic-headline">For You</h2>
        <span className="filter-icon-container">
          <button onClick={() => setShowSortOptions(prev => !prev)} className="filter-icon-button">
            <img src={filterIcon} alt="Filter" />
          </button>
          {showSortOptions && (
            <ul className="sort-options">
              {sortOptions.map((option) => (
                <li key={option.value} onClick={() => handleSortChange(option.value)}>
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </span>
      </div>
      <div className="main-content">
        {sortedArticles.map((article) => (
          <div className="article-container" key={article.id}>
            <ArticleCard article={article} onDelete={() => {}} />
          </div>
        ))}
      </div>

    </div>
  );
};

export default CustomFeed;