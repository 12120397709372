import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid, Alert } from '@mui/material';
import { UserContext } from './AuthProvider'; // Assuming this is where you keep your user context
import config from './config';

const RegistrationForm = () => {
  const [user, setUser] = useState({ username: '', email: '', password: '', confirmPassword: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser: setGlobalUser } = useContext(UserContext); // This assumes you have a setUser function in your UserContext

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const registrationResponse = await fetch(`${config.apiUrl}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
      });

      const registrationData = await registrationResponse.json();
      if (registrationResponse.ok) {
        // After successful registration, log the user in automatically
        const loginResponse = await fetch(`${config.apiUrl}/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: user.username, password: user.password }),
          credentials: 'include' // For cookies, if your authentication method requires it
        });

        const loginData = await loginResponse.json();
        if (loginResponse.ok) {
          setGlobalUser(loginData.user); // Update the global user context
          navigate('/'); // Redirect to the home page
        } else {
          setError('Automatischer Login fehlgeschlagen');
        }
      } else {
        setError('Registrierung fehlgeschlagen');
      }
    } catch (error) {
      setError('Server konnte nicht erreicht werden');
    }
  };

  return (
    <Container maxWidth="xs" style={{ backgroundColor: 'white', marginTop: '20px', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
      <Typography variant="h5" style={{ margin: '20px 0' }}>Register</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Username" 
              name="username" 
              value={user.username} 
              onChange={handleChange} 
              variant="outlined" 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Email" 
              name="email" 
              value={user.email} 
              onChange={handleChange} 
              variant="outlined" 
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Password" 
              name="password" 
              value={user.password} 
              onChange={handleChange} 
              variant="outlined" 
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Confirm Password" 
              name="confirmPassword" 
              value={user.confirmPassword} 
              onChange={handleChange} 
              variant="outlined" 
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Register
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default RegistrationForm;
