import { Link } from 'react-router-dom';
import './ContentOverviewStyles.css';
import MultiStepProgressBar from '../util/MultiStepProgressBar';

const ContentOverview = ({ contentTypes }) => {

  console.log("in content overview, content_types: ", contentTypes)

  return (
    <div className="landing-page-container">
      <MultiStepProgressBar currentStep={1} totalSteps={3} />
      <div className="section-content">
        {contentTypes.map((content_type, index) => (
          <div key={index} className={'materialCard'} style={{ backgroundImage: `url(${content_type.thumbnail_url})` }}>
            <Link to={`/content-hub/${encodeURIComponent(content_type.name).toLowerCase()}`}>
              <div className="materialCard-content">
                <h3>{content_type.name}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentOverview;
