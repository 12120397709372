import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CockpitContainer from './CockpitContainer';
import Twitter from '../images/tweet_generator.webp';
import Instagram from '../images/instagram_generator.webp';
import Facebook from '../images/facebook_generator.webp';
import LinkedIn from '../images/linkedin_generator.webp';
import Artikel from '../images/article_generation.webp';
import Blog from '../images/blog_generator.webp';
import Mail from '../images/mail_generator.webp';

const contentTypes = [
  { name: 'Twitter', image: Twitter },
  { name: 'Instagram', image: Instagram },
  { name: 'Facebook', image: Facebook },
  { name: 'LinkedIn', image: LinkedIn },
  { name: 'Artikel', image: Artikel },
  { name: 'Blog', image: Blog },
  { name: 'E-Mail', image: Mail },
];

const ContentTypeSelector = ({ onClose, article }) => {
  const [selectedType, setSelectedType] = useState(null);

  if (selectedType) {
    return <CockpitContainer onClose={onClose} article={article} initialTab={contentTypes.findIndex(type => type.name === selectedType)} />;
  }

  return (
    <div className="popup">
      <div className="popup-inner">
          <button className="close-button" onClick={onClose}>&times;</button>
        <div className="popup-content">
          <div className="content-type-grid">
            {contentTypes.map((type, index) => (
              <div key={index} className="materialCard materialCardCockpit" style={{ backgroundImage: `url(${type.image})` }}>
                <div className="materialCard-content" onClick={() => setSelectedType(type.name)}>
                  <h3>{type.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTypeSelector;