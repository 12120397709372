import React from 'react';
import '../../App.css';
import '../NewsArticleStyles.css';

const AggregateButton = ({ loading, onClick, messages }) => {
  return (
    <div className="centered aggregateButtonContainer">
      <button className="button" onClick={onClick} disabled={loading}>
        {loading ? 'Loading...' : 'Load Articles'}
      </button>
      {loading && <div className="loader"></div>}
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
      </div>
    </div>
  );
};

export default AggregateButton;