import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import '../App.css';
import '../feed/NewsArticleStyles.css';
import Section from '../Section';
import config from '../config';

const BlogContainer = ({ article, hasServiceAccess, isLoading, setIsLoading, returnedText, setReturnedText, setLoadingModifiedText }) => {
  const [error, setError] = useState(null);

  const handleSubmitBlog = async () => {
    setLoadingModifiedText(true);
    setError(null);
    try {
      console.log("generating article")
      setReturnedText("Blog wird generiert...");
      if (!article.text) {
        throw new Error("Article text is empty");
      }
      const response = await fetch(`${config.apiUrl}/article/create-blog`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          texts: [article.text],
          wordcount: 300
        })
      });
    
      if (!response.ok) {
        throw new Error("Failed to generate blog");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value);
        const lines = chunk.split('|END|');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') break;
            accumulatedResponse += data;
            setReturnedText(accumulatedResponse);
          }
        }
      }
      const sourcesText = `- ${article.title}: ${article.url}`;
      setReturnedText(accumulatedResponse + '\n\n' + sourcesText)

    } catch (error) {
      console.error('Failed to fetch the post text:', error);
      setError(error.message);
      setReturnedText("");
    } finally {
      setLoadingModifiedText(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!returnedText) handleSubmitBlog();
  }, []);
  
  return (
    <div className="">
      <Section className="postTextSection">
        <div className="inputAlteredCard textArea">
          <div className="inputCard-content inputCard-content-overflow">
            <div className="textarea-container textarea-altered-text">
              
                <textarea className="" value={returnedText} readOnly />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default BlogContainer;