import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const CouponManagement = () => {
  const [coupons, setCoupons] = useState([]);
  const [couponAmount, setCouponAmount] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchCoupons = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/coupon/get-all-unused`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coupons');
      }
      const data = await response.json();
      setCoupons(data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
      setMessage('Failed to fetch coupons');
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleCreateCoupons = async (e) => {
    e.preventDefault();
    if (!couponAmount) {
      setMessage('Please specify the number of coupons to create');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/coupon/create/${couponAmount}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to create coupons');
      }

      setMessage('Coupons created successfully');
      setCouponAmount('');
      fetchCoupons();
    } catch (error) {
      console.error('Error creating coupons:', error);
      setMessage(`Failed to create coupons: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 bg-gray-100">
      <Link className="text-blue-600 hover:text-blue-800 underline mb-4 inline-block" to="/admin">Back to Admin Dashboard</Link>

      <h2 className="text-2xl font-bold mb-4 text-gray-800">Coupon Management</h2>
      {message && <p className="text-sm font-medium text-red-600 mb-4">{message}</p>}

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Create New Coupons</h3>
        <form onSubmit={handleCreateCoupons} className="space-y-4">
          <div>
            <label htmlFor="couponAmount" className="block text-sm font-medium text-gray-700">Number of Coupons:</label>
            <input
              id="couponAmount"
              type="number"
              value={couponAmount}
              onChange={(e) => setCouponAmount(e.target.value)}
              min="1"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button 
            type="submit" 
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Create Coupons
          </button>
        </form>
      </div>

      <h3 className="text-xl font-semibold mb-4 text-gray-700">Active Coupons</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Code</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Duration (Days)</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {coupons.map((coupon) => (
              <tr key={coupon.id}>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{coupon.id}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{coupon.code}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{coupon.durationInDays}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponManagement;