import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../AuthProvider';
import ContentTypeSelector from '../../cockpit/ContentTypeSelector';
import '../NewsArticleStyles.css';
import '../../App.css';
import { Pie } from 'react-chartjs-2';
import config from '../../config';
import defaultPreview from '../../images/background5.webp';
import trashIcon from '../../images/trash-outline.svg';
import ArticleCharts from './ArticleCharts';
import ArticleActions from './ArticleActions';
import ArticleTitle from './ArticleTitle';
import ArticleImage from './ArticleImage';
import DeleteButton from './DeleteButton';


const ArticleCard = ({ article, onDelete }) => {
  const cardBackContentRef = useRef(null);
  const cardFrontContentRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [isCardFlipped, setIsCardFlipped] = useState(false);

  const { user } = useContext(UserContext);
  const isAdmin = user?.roles?.includes('admin');

  const adjustFlipContainerHeight = () => {
    const containers = document.querySelectorAll('.flip-container');
    
    containers.forEach(container => {
      const front = container.querySelector('.front');
      if (front) {
        const height = front.offsetHeight; // Get the height of the front side
        container.style.height = `${height}px`; // Apply this height to the flip-container
      }
    });
  };
    

  const toggleCardFlip = () => {
    setIsCardFlipped(!isCardFlipped);
  };

  const handleDeleteArticle = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/delete-article/${article.id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (response.ok) {
        onDelete(article.id);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const adjustFontSizeToFit = (ref) => {
    if (ref.current) {
      let container = ref.current;
      let fontSize = 1.0;
      let lineHeight = 1.4;

      const maxWidth = 701; // Threshold for device width
      let maxContainerHeight = window.innerWidth > maxWidth ? 140 : 180;
  
  
      container.style.fontSize = `${fontSize}em`;
      container.style.lineHeight = `${lineHeight * fontSize}em`; // Line height as a multiple of font size
  
      while (container.offsetHeight > maxContainerHeight && fontSize > 0.1) {
        fontSize-=0.05; // Decrease font size
        container.style.fontSize = `${fontSize}em`;
        container.style.lineHeight = `${lineHeight * fontSize}em`; // Adjust line height proportionally
      }
    }
  };

  useEffect(() => {
    adjustFontSizeToFit(cardBackContentRef); // Adjust the font size to fit within 140px height
    adjustFontSizeToFit(cardFrontContentRef); // Adjust the font size to fit within 140px height
    adjustFlipContainerHeight();
  }, [isCardFlipped, article.reasoning]);


  const pieData_1 = {
    labels: ['Allgemein'],
    datasets: [
      {
        data: [article.score_general, 100 - article.score_general],
        backgroundColor: ['#030027', 'rgba(0, 0, 0, 0.0)'],
      },
    ],
  };

  const pieData_2 = {
    labels: ['Disruption'],
    datasets: [
      {
        data: [article.score_disruptiveness, 100 - article.score_disruptiveness],
        backgroundColor: ['#151e3f', 'rgba(0, 0, 0, 0.0)'],
      },
    ],
  };

  const pieOptions = {
    responsive: true,            // Make the chart responsive
    maintainAspectRatio: true,  // Maintain aspect ratio based on container size
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 11,
          },
          boxWidth: 14,
          padding: 8,
        },
      },
      title: {
        display: false,
      },
    },
    interaction: {
      mode: 'none', // Disable interactivity
    },
  };
  const pieChartStyle = {
    display: 'block',
    width: '100%',
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth === 0 || naturalHeight === 0) {
      event.target.src = defaultPreview;
    }
  };
  
  return (
    
    <div>
      <div className={`flip-container ${isCardFlipped ? 'flipped' : ''}`} style={{ position: 'relative' }}>
        <div className="flipper">
          <div className="front">
            <div className="mainContainer">
              <div className="row card-back-content" ref={cardFrontContentRef}>
                <div className="centered text-center">
                  <ArticleTitle title={article.title}/>
                  <p>{article.summary}</p>
                </div>
              </div>
              <div className="row">
                <ArticleImage article={article} />
                <ArticleCharts article={article} />
              </div>
              <ArticleActions article={article} onFlip={toggleCardFlip} onShowCockpit={() => setShowPopup(true)} leftBtnTxt="Evaluierung" />
            </div>
            {isAdmin && <DeleteButton onDelete={handleDeleteArticle} />}
          </div>
          <div className="back">
            <div className="mainContainer">
              <div className="row card-back-content" ref={cardBackContentRef}>
                {article.reasoning}
              </div>
              <div className="row">
                <ArticleImage article={article} />
                <ArticleCharts article={article} />
              </div>
              <ArticleActions article={article} onFlip={toggleCardFlip} onShowCockpit={() => setShowPopup(true)} leftBtnTxt="Zusammenfassung" />
            </div>
          </div>
        </div>
      </div>
      
      {showPopup && (
        <ContentTypeSelector onClose={() => setShowPopup(false)} article={article} />
      )}
    </div>
  );
};

export default ArticleCard;