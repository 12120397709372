import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleGenerator from './generators/ArticleGenerator';
import TweetGenerator from './generators/TweetGenerator';
import InstagramGenerator from './generators/InstagramGenerator';
import LinkedInGenerator from './generators/LinkedInGenerator';
import FacebookGenerator from './generators/FacebookGenerator';
import MailGenerator from './generators/MailGenerator';
import BlogGenerator from './generators/BlogGenerator';

const ContentGenerator = ({ selectedArticles }) => {
  const { 'content-type': encodedContentType } = useParams();
  const contentType = decodeURIComponent(encodedContentType);
  
  const [generatedContent, setGeneratedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const renderGenerator = () => {
    switch (contentType.toLowerCase()) {
      case 'article generation':
        return (
          <ArticleGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      case 'mail generation':
        return (
          <MailGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      case 'tweet generation':
        return (
          <TweetGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      
      case 'facebook generation':
        return (
          <FacebookGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      case 'instagram generation':
        return (
          <InstagramGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      case 'linkedin generation':
        return (
          <LinkedInGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      case 'blog generation':
        return (
          <BlogGenerator 
            selectedArticles={selectedArticles}
            setGeneratedContent={setGeneratedContent}
            setIsLoading={setIsLoading}
            setError={setError}
          />
        );
      default:
        return <div>Unknown content type: {contentType}</div>;
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      
      {renderGenerator()}

      
      {error && (
        <div className="mt-4 text-red-600">
          Error: {error}
        </div>
      )}

      {generatedContent && (
        <div className="mt-8">
          <h3 className="text-2xl font-semibold mb-4">Generated Content:</h3>
          <div className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap">
          {generatedContent.split('\n').map((paragraph, idx) => (
              paragraph ?
              <p key={idx}>{paragraph}</p> : 
              <br key={idx} /> // Insert a <br> for every empty result from a split at `\n`
          ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentGenerator;