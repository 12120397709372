import 'chart.js/auto';
import React, { useEffect  } from 'react';

import '../App.css';
import '../feed/NewsArticleStyles.css';
import refreshIcon from '../images/refresh-outline.svg';
import copyIcon from '../images/copy-outline.svg'
import Section from '../Section';

import config from '../config';

const EmailContainer = ({ article, hasServiceAccess, isLoading, setIsLoading, emailText, setEmailText, setLoadingEmailText }) => {

    const handleSubmitEmail = async () => {
        setLoadingEmailText(true)
        try {
          setEmailText("E-Mail wird generiert...");
          const response = await fetch(`${config.apiUrl}/article/create-email`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ texts: [article.text] })
          });
          setLoadingEmailText(false)
          if (!response.ok) {
            throw new Error('Failed to test prompt');
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let accumulatedResponse = '';

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value);
            const lines = chunk.split('|END|');

            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);
                if (data === '[DONE]') break;
                accumulatedResponse += data;
                setEmailText(accumulatedResponse);
              }
            }
          }
          setEmailText(accumulatedResponse + `\n\nQuelle: ${article.url}`);
          
        } catch (error) {
          console.error('Failed to fetch the post text:', error);
        } finally {
          setIsLoading(false);
        }
    };
    useEffect(() => {
      if (!emailText) handleSubmitEmail()
    }, []);
    
    return (
        <div className="">
                  
            <Section className="postTextSection">
                <div className="inputAlteredCard textArea">
                    <div className="inputCard-content inputCard-content-overflow">

                        <div className="textarea-container textarea-altered-text">
                        <textarea className="" value={emailText}/>

                        </div>
                        
                    </div>
                </div>
            </Section>      
                  
                  
        </div>
    )

}

export default EmailContainer;