import React from 'react';
import ArticleCard from './ArticleCard';
import '../../App.css';
import '../NewsArticleStyles.css';

const ArticleList = ({ articles, onDelete }) => {
  return (
    <div className="main-content">
      {articles.map((article) => (
        <div className="article-container" key={article.id}>
          <ArticleCard article={article} onDelete={onDelete} />
        </div>
      ))}
    </div>
  );
};

export default ArticleList;