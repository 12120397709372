import React, { useState, useEffect, useCallback, useContext, useMemo  } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import 'chart.js/auto';
import '../App.css';
import './NewsArticleStyles.css';
import { UserContext } from '../AuthProvider';
import TopicHeader from './components/TopicHeader';
import ArticleList from './components/ArticleList';
import AggregateButton from './components/AggregateButton';
import DateNavigation from './components/DateNavigation';

import config from '../config';

const socket = io(`${config.apiUrl}`);

const NewsArticles = () => {
  const { topic: topicNameFromUrl } = useParams();
  const [loading, setLoading] = useState(false);
  const [articlesLoading, setArticlesLoading] = useState(false);
  const [sortCriteria, setSortCriteria] = useState('general'); // Added state for sorting criteria
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [messages, setMessages] = useState([]);
  const [articlesData, setArticlesData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now() - 86400000).toISOString().split('T')[0]);

  const { user } = useContext(UserContext);
  const isAdmin = user?.roles?.includes('admin');

  const sortOptions = [
    { value: 'general', label: 'Sortieren nach allg. Bewertung' },
    { value: 'disruptiveness', label: 'Sortieren nach Disruption' }
  ];

  const sortArticles = (articles) => {
    if (!Array.isArray(articles)) {
      console.warn('Attempted to sort non-array:', articles);
      return [];
    }

    // Sort by the specified criteria directly
    return articles.sort((a, b) => {
      if (sortCriteria === 'general') {
        return b.score_general - a.score_general; // Descending order by general score
      } else if (sortCriteria === 'disruptiveness') {
        return b.score_disruptiveness - a.score_disruptiveness; // Descending order by disruptiveness
      }
      // Fallback, though not specified as part of the function's behavior
      return 0;
    });
  };
  

  const handleSortChange = (value) => {
    setSortCriteria(value);
    setShowSortOptions(false);
  };

  const getArticles = useCallback(() => {
    setLoading(true);
    const endpoint = `/get-articles/${topicNameFromUrl}?date=${selectedDate}`;

    fetch(`${config.apiUrl}${endpoint}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((newData) => {
        setArticlesData(newData); // Store fetched data without sorting
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
        setLoading(false);
      });
  }, [topicNameFromUrl, selectedDate]);

  const sortedArticles = useMemo(() => {
    if (!articlesData) {
      return; // Exit the function if newData is not an array
    }  
    return sortArticles(articlesData, sortCriteria);
  }, [articlesData, sortCriteria]);

  useEffect(() => {
    getArticles();
  }, [getArticles, selectedDate]);

  
  const handleDeleteArticle = (articleId) => {
    setArticlesData(currentArticles => currentArticles.filter(article => article.id !== articleId));
  };

  const updateArticlesData = (newData) => {
    if (!Array.isArray(newData)) {
      return; // Exit the function if newData is not an array
    }  
    setArticlesData(currentArticles => {
      // Default currentArticles to an empty array if it's falsy
      const articles = currentArticles || [];
      const newUniqueArticles = newData.filter(newArticle => 
        !articles.some(existingArticle => existingArticle.url === newArticle.url)
      );
      return [...articles, ...newUniqueArticles];
    });
  };

  useEffect(() => {
    getArticles();
  }, [getArticles, sortCriteria, selectedDate]);

  const handlePrevDate = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    setSelectedDate(currentDate.toISOString().split('T')[0]);
  };

  const handleNextDate = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    setSelectedDate(currentDate.toISOString().split('T')[0]);
  };

  const isNextButtonDisabled = () => {
    return new Date(selectedDate).toISOString().split('T')[0] >= new Date(Date.now()).toISOString().split('T')[0];
  };

  useEffect(() => {
    socket.on('update', (data) => {
      setLoading(true);
      setMessages(prev => [...prev, data.message]);
    });
  
    socket.on('aggregation_complete', () => {
      // Clear messages and fetch updated articles when aggregation is complete
      setMessages([]);
      getArticles();
      setLoading(false);
    });
  
    return () => {
      socket.off('update');
      socket.off('aggregation_complete');
    };
  }, [getArticles]);
  const toggleSortOptions = () => {
    setShowSortOptions((prevState) => !prevState);
  };

  /*const handleAggregateClick = () => {
    setLoading(true);
    const endpoint = `/java/aggregate`;
  
    const getTopicIdByName = async (name) => {
      try {
        const response = await fetch(`${config.apiUrl}/topic/get-id-by-name/${name}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Topic not found');
        }
  
        const data = await response.json();
        return data.id;
      } catch (error) {
        console.error('Error fetching topic ID:', error);
        setLoading(false);
        return null;
      }
    };
    getTopicIdByName(topicNameFromUrl).then((topicId) => {
      if (topicId !== null) {
        const requestBody = {
          topicId: topicId,
          date: selectedDate
        };
  
        fetch(`${config.apiUrl}${endpoint}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody)
        })
        .then(() => {
          setLoading(false);
          getArticles();
        })
        .catch((error) => {
          console.error('Error during aggregation:', error);
          setLoading(false);
        });
      }
    });
  };*/
  
  const handleAggregateClick = () => {
    setLoading(true);
    const endpoint = `/aggregate-articles/${topicNameFromUrl}`;

    fetch(`${config.apiUrl}${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      // Handle response
      setLoading(false);
      // Optionally, refresh articles after aggregation
      getArticles();
    })
    .catch((error) => {
      console.error('Error during aggregation:', error);
      setLoading(false);
    });
  };
  
  

  return (
    <div className="content-section">
      <TopicHeader 
        topicName={topicNameFromUrl} 
        sortOptions={sortOptions} 
        onSortChange={handleSortChange} 
      />
      <ArticleList 
        articles={sortedArticles} 
        onDelete={handleDeleteArticle} 
      />
      {isAdmin && (
        <AggregateButton 
          loading={loading} 
          onClick={handleAggregateClick} 
          messages={messages} 
        />
      )}
      <DateNavigation 
        selectedDate={selectedDate} 
        onPrevDate={handlePrevDate} 
        onNextDate={handleNextDate} 
        isNextButtonDisabled={isNextButtonDisabled()} 
      />
    </div>
  );
};


export default NewsArticles;
