import React, { useState } from 'react';
import ArticleSelection from './../../../content-hub/ArticleSelectionComponent';
import config from '../../../config';
import Heatmap from './Heatmap';
import MultiStepProgressBar from '../../../util/MultiStepProgressBar';

const SimilarityDashboard = ({ topics }) => {
    const [step, setStep] = useState(1);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [similarityScores, setSimilarityScores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [textInputs, setTextInputs] = useState(['']);

    const addTextField = () => {
        setTextInputs([...textInputs, '']);
    };

    const handleTextInputChange = (index, value) => {
        const newTextInputs = [...textInputs];
        newTextInputs[index] = value;
        setTextInputs(newTextInputs);
    };

    const calculateSimilarity = async (articles = []) => {
        const nonEmptyTextInputs = textInputs.filter(text => text.trim() !== '');
        const articlesWithText = [
            ...articles,
            ...nonEmptyTextInputs.map((text, index) => ({
                id: `custom-text-${index}`,
                title: text.substring(0, 40),
                text: text
            }))
        ];

        if (articlesWithText.length < 2) {
            alert("Please select at least two articles or add text to compare.");
            return;
        }

        setSelectedArticles(articlesWithText);
        setLoading(true);

        try {
            // Get embeddings for the selected articles and custom text
            const embeddingsResponse = await fetch(`${config.apiUrl}/article/embeddings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ articles: articlesWithText }),
                credentials: 'include',
            });

            if (!embeddingsResponse.ok) {
                throw new Error('Failed to fetch embeddings');
            }

            const embeddings = await embeddingsResponse.json();

            // Calculate similarity using these embeddings
            const similarityResponse = await fetch(`${config.apiUrl}/article/similarity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ embeddings }),
                credentials: 'include',
            });

            if (!similarityResponse.ok) {
                throw new Error('Failed to calculate similarity');
            }

            const similarityMatrix = await similarityResponse.json();
            setSimilarityScores(similarityMatrix);
            setStep(2); // Move to the next step
        } catch (error) {
            console.error('Error calculating similarity:', error);
            alert('An error occurred while calculating similarity. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <ArticleSelection 
                            topics={topics} 
                            onProceed={calculateSimilarity}
                            contentType="article"
                        />
                        <div className="mt-8">
                            <h3 className="text-lg font-semibold mb-4">Add Custom Text</h3>
                            {textInputs.map((text, index) => (
                                <div key={index} className="mb-4">
                                    <textarea
                                        className="w-full p-2 border rounded"
                                        value={text}
                                        onChange={(e) => handleTextInputChange(index, e.target.value)}
                                        placeholder={`Enter custom text ${index + 1}`}
                                        rows="3"
                                    />
                                </div>
                            ))}
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded mr-4"
                                onClick={addTextField}
                            >
                                Add Another Text Field
                            </button>
                            {textInputs.some(text => text.trim() !== '') && (
                                <button
                                    className="bg-green-500 text-white px-4 py-2 rounded"
                                    onClick={() => calculateSimilarity()}
                                >
                                    Proceed with Custom Text
                                </button>
                            )}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="mt-8">
                        <Heatmap 
                            similarityMatrix={similarityScores} 
                            labels={selectedArticles.map(article => article.title)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="max-w-4xl mx-auto px-4 py-8">
                <MultiStepProgressBar currentStep={step} totalSteps={2} />
            </div>
            <div className="p-4">
                {loading ? (
                    <div className="mt-4 text-center">
                        <p className="text-lg font-semibold">Calculating similarity...</p>
                    </div>
                ) : (
                    renderStep()
                )}
            </div>
        </>
    );
};

export default SimilarityDashboard;