import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleSelection from './ArticleSelectionComponent';
import ContentGenerator from './ContentGenerator';
import MultiStepProgressBar from '../util/MultiStepProgressBar';

const ContentHubWrapper = ({ topics }) => {
  const [step, setStep] = useState(2);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const { 'content-type': contentType } = useParams();

  const handleProceedToGenerator = (articles) => {
    setSelectedArticles(articles);
    setStep(3);
  };

  return (
    <>
        <div className="max-w-4xl mx-auto px-4 py-8">
            <MultiStepProgressBar currentStep={step} totalSteps={3} />
        </div>

        <div className="mt-12">
            {step === 2 && (
            <ArticleSelection 
                topics={topics} 
                onProceed={handleProceedToGenerator}
                contentType={contentType}
            />
            )}
            {step === 3 && (
            <ContentGenerator 
                selectedArticles={selectedArticles}
                contentType={contentType}
            />
            )}
        </div>
    </>
  );
};

export default ContentHubWrapper;