import React from 'react';
import defaultPreview from '../../images/background5.webp';

const ArticleImage = ({ article }) => {
  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth === 0 || naturalHeight === 0) {
      event.target.src = defaultPreview;
    }
  };

  return (
    <div className="column-sm">
      <div className="imageLink">
        <a href={article.url} target="_blank" rel="noopener noreferrer">
          <img
            src={article.thumbnail || defaultPreview}
            alt={article.title || 'Link preview'}
            onLoad={handleImageLoad}
            onError={(event) => { event.target.src = defaultPreview; }}
          />
          <div className="flexOverlayContainer">
            <div className="imageLinkOverlay">Artikel lesen</div>
          </div>                   
        </a>
      </div>
    </div>
  );
};

export default ArticleImage;