import React from 'react';
import trashIcon from '../../images/trash-outline.svg';

const DeleteButton = ({ onDelete }) => (
  <button 
    onClick={onDelete} 
    style={{
      position: 'absolute', 
      bottom: '10px', 
      right: '18px', 
      border: 'none', 
      cursor: 'pointer', 
      backgroundColor: 'transparent'
    }}
  >
    <img src={trashIcon} alt="Delete" style={{ width: '24px', height: '24px' }} />
  </button>
);
export default DeleteButton;