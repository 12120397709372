import React, { useState, useEffect, useContext } from 'react';
import config from '../config'; // Your config file for API URL
import './UserProfileComponent.css';
import { UserContext } from '../AuthProvider';

const UserProfileComponent = () => {
  const [topics, setTopics] = useState([]);
  const [interestedTopics, setInterestedTopics] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [hasServiceAccess, setHasServiceAccess] = useState(false);
  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isTwitterConnected, setIsTwitterConnected] = useState(false);
  const [contentTypes, setContentTypes] = useState([]);
  const [exampleTexts, setExampleTexts] = useState({});
  const [contentTypeExamples, setContentTypeExamples] = useState([]);

  const { user, setUser, setServiceAccessUpdated } = useContext(UserContext);

  useEffect(() => {
    fetchTopics();
    fetchInterestedTopics();
    checkTwitterConnection();
    checkFacebookConnection();
    checkServiceAccess();
    fetchContentTypeExamples();
  }, []);

  const fetchContentTypes = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/content/get-all-active`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to fetch content types');
      const data = await response.json();
      setContentTypes(data);
    } catch (error) {
      console.error('Error fetching content types:', error);
    }
  };

  const fetchContentTypeExamples = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/content-type-examples`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to fetch content type examples');
      const data = await response.json();
      setContentTypeExamples(data);
    } catch (error) {
      console.error('Error fetching content type examples:', error);
    }
  };

  const handleExampleTextChange = (contentTypeId, text) => {
    setContentTypeExamples(prev => prev.map(item => 
      item.content_type_id === contentTypeId ? {...item, example_text: text} : item
    ));
  };

  const saveExampleText = async (contentTypeId) => {
    setLoading(true);
    try {
      const exampleTextObj = contentTypeExamples.find(item => item.content_type_id === contentTypeId);
      const exampleText = exampleTextObj?.example_text || '';

      const response = await fetch(`${config.apiUrl}/content-type-example`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content_type_id: contentTypeId,
          example_text: exampleText
        }),
      });

      if (!response.ok) throw new Error('Failed to save example text');
      const data = await response.json();

      setContentTypeExamples(prev => prev.map(item => 
        item.content_type_id === contentTypeId 
          ? {...item, example_id: exampleText ? data.id : null, example_text: exampleText} 
          : item
      ));

      setMessage(exampleText ? 'Example text saved successfully' : 'Example text deleted successfully');
    } catch (error) {
      console.error('Error saving example text:', error);
      setMessage('Failed to save example text');
    } finally {
      setLoading(false);
    }
  };

  const deleteExampleText = async (contentTypeId) => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/content-type-example`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content_type_id: contentTypeId
        }),
      });

      if (!response.ok) throw new Error('Failed to delete example text');

      setContentTypeExamples(prev => prev.map(item => 
        item.content_type_id === contentTypeId 
          ? {...item, example_id: null, example_text: ''} 
          : item
      ));

      setMessage('Example text deleted successfully');
    } catch (error) {
      console.error('Error deleting example text:', error);
      setMessage('Failed to delete example text');
    } finally {
      setLoading(false);
    }
  };



  const activateCoupon = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/user/activate-coupon`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: couponCode }),
      });
      if (!response.ok) throw new Error(await response.text());
      setMessage('Coupon erfolgreich aktiviert');
      setCouponCode('');
      checkServiceAccess(); // Refresh the service access status
    } catch (error) {
      console.error('Error activating coupon:', error);
      setMessage(error.toString());
    } finally {
      setLoading(false);
    }
  };
  
  const checkServiceAccess = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/check-service-access`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to check service access');
      const data = await response.json();
      setHasServiceAccess(data.has_access);
      setServiceAccessUpdated(data.has_access);
    } catch (error) {
      console.error('Error checking service access:', error);
    }
  };
  

  // New function to check Twitter connection
  const checkTwitterConnection = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/twitter/connected`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsTwitterConnected(data.connected);
    } catch (error) {
      console.error('Error checking Twitter connection:', error);
    }
  };

  const checkFacebookConnection = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/facebook/connected`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsFacebookConnected(data.connected);
    } catch (error) {
      console.error('Error checking Facebook connection:', error);
    }
  };

  // Function to initiate Twitter authentication
  const connectTwitter = async () => {
    window.location.href = `${config.apiUrl}/twitter/auth`; // Redirect to backend auth route
  };

  const disconnectTwitter = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/twitter/disconnect`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsTwitterConnected(data.connected);
    } catch (error) {
      console.error('Error checking Twitter connection:', error);
    }
  };

  const connectFacebook = async () => {
    window.location.href = `${config.apiUrl}/facebook/auth`; // Redirect to backend auth route for Facebook
  };

  const disconnectFacebook = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/facebook/disconnect`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsFacebookConnected(data.connected);
    } catch (error) {
      console.error('Error checking Facebook connection:', error);
    }
  };

  const fetchTopics = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/topic/get-all-active`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch topics');
      const data = await response.json();
      setTopics(data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchInterestedTopics = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/interested-topics`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch interested topics');
      const data = await response.json();
      setInterestedTopics(new Set(data.interested_topics.map(topic => topic.id)));
    } catch (error) {
      console.error('Error fetching interested topics:', error);
    }
  };

  const handleCheckboxChange = (topicId) => {
    setInterestedTopics((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(topicId)) {
        newSet.delete(topicId);
      } else {
        newSet.add(topicId);
      }
      return newSet;
    });
  };

  const saveChanges = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/user/set-interested-topics`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic_ids: Array.from(interestedTopics) }),
      });
      if (!response.ok) throw new Error('Failed to update interested topics');
      setMessage('Themen erfolgreich aktualisiert');
    } catch (error) {
      console.error('Error updating interested topics:', error);
      setMessage('Aktualisierung fehlgeschlagen');
    } finally {
      setLoading(false);
    }
  };
  const logout = async () => {
    try {
      // Call the backend to logout the user
      const response = await fetch(`${config.apiUrl}/logout`, {
        method: 'POST', // or 'GET', depending on how your backend expects to handle logout
        credentials: 'include', // Necessary for cookies if you're using session-based auth
      });
      if (response.ok) {
        // Redirect to the login page
        window.location.href = '/login';
      } else {
        // Handle any errors or unsuccessful logout attempts
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };
  
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Deine Themen</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">Thema</th>
              <th className="px-4 py-2">Interesse</th>
            </tr>
          </thead>
          <tbody>
            {topics.map((topic) => (
              <tr key={topic.id} className="border-b">
                <td className="px-4 py-2">{topic.name}</td>
                <td className="px-4 py-2">
                  <label className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      checked={interestedTopics.has(topic.id)}
                      onChange={() => handleCheckboxChange(topic.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button onClick={saveChanges} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" disabled={loading}>
          {loading ? 'Speichert...' : 'Speichern'}
        </button>
        {message && <span className="ml-2 text-sm text-gray-600">{message}</span>}
      </div>

      <h2 className="text-2xl font-bold mt-8 mb-4">Stilvorlagen für Inhaltstypen</h2>
      {contentTypeExamples.map((item) => (
        <div key={item.content_type_id} className="mb-4">
          <h3 className="text-lg font-semibold mb-2">{item.content_type_name}</h3>
          <textarea
            value={item.example_text}
            onChange={(e) => handleExampleTextChange(item.content_type_id, e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
          />
          <button
            onClick={() => saveExampleText(item.content_type_id)}
            className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded text-sm mr-2"
            disabled={loading}
          >
            Speichern
          </button>
          <button
            onClick={() => deleteExampleText(item.content_type_id)}
            className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded text-sm"
            disabled={loading || !item.example_id}
          >
            Löschen
          </button>
        </div>
      ))}

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Mit Social Media verbinden</h2>
        <div className="flex items-center justify-between mb-2">
          <span>Twitter:</span>
          {isTwitterConnected ? (
            <button onClick={disconnectTwitter} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Trennen</button>
          ) : (
            <button onClick={connectTwitter} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Mit Twitter verbinden</button>
          )}
        </div>
        <div className="flex items-center justify-between">
          <span>Facebook:</span>
          {isFacebookConnected ? (
            <button onClick={disconnectFacebook} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Trennen</button>
          ) : (
            <button onClick={connectFacebook} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Mit Facebook verbinden</button>
          )}
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Coupon aktivieren</h2>
        <div className="flex items-center">
          <input
            type="text"
            placeholder={hasServiceAccess ? "Du hast bereits Servicezugriff" : "Coupon Code eingeben"}
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="border p-2 mr-2 rounded"
          />
          <button
            onClick={activateCoupon}
            disabled={loading || hasServiceAccess}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
          >
            Aktivieren
          </button>
        </div>
        {message && <p className="mt-2 text-sm text-gray-600">{message}</p>}
      </div>

      <div className="mt-8">
        <button onClick={logout} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Ausloggen</button>
      </div>
    </div>
  );
};

export default UserProfileComponent;
