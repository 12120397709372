import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const UserRoleManagement = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [topics, setTopics] = useState([]);
  const [newRoleName, setNewRoleName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchUsersRolesAndTopics = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/users-roles-and-topics`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users, roles, and topics');
      }
      const data = await response.json();
      setUsers(data.users);
      setRoles(data.roles);
      setTopics(data.topics);
    } catch (error) {
      console.error('Error fetching users, roles, and topics:', error);
      setMessage('Failed to fetch users, roles, and topics');
    }
  };

  useEffect(() => {
    fetchUsersRolesAndTopics();
  }, []);
  const handleUpdateRoleTopics = async () => {
    if (!selectedRoleId) {
      setMessage('Please select a role');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/role/${selectedRoleId}/topics`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic_ids: selectedTopics }),
      });

      if (!response.ok) {
        throw new Error('Failed to update role topics');
      }

      setMessage('Role topics updated successfully');
      fetchUsersRolesAndTopics();
    } catch (error) {
      console.error('Error updating role topics:', error);
      setMessage('Failed to update role topics');
    } finally {
      setLoading(false);
    }
  };

  const handleResetRoleTopics = async () => {
    if (!selectedRoleId) {
      setMessage('Please select a role');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/role/${selectedRoleId}/topics/reset`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to reset role topics');
      }

      setMessage('Role topics reset successfully');
      setSelectedTopics([]);
      fetchUsersRolesAndTopics();
    } catch (error) {
      console.error('Error resetting role topics:', error);
      setMessage('Failed to reset role topics');
    } finally {
      setLoading(false);
    }
  };

  const handleAssignRole = async () => {
    if (!selectedUserId || !selectedRoleId) {
      setMessage('Please select both a user and a role');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/assign-role`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: selectedUserId, roleId: selectedRoleId }),
      });

      if (!response.ok) {
        throw new Error('Failed to assign role');
      }

      setMessage('Role assigned successfully');
      fetchUsersRolesAndTopics();
    } catch (error) {
      console.error('Error assigning role:', error);
      setMessage('Failed to assign role');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateRole = async () => {
    if (!newRoleName) {
      setMessage('Please enter a role name');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/create-role`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newRoleName }),
      });

      if (!response.ok) {
        throw new Error('Failed to create role');
      }

      setMessage('Role created successfully');
      setNewRoleName('');
      fetchUsersRolesAndTopics();
    } catch (error) {
      console.error('Error creating role:', error);
      setMessage('Failed to create role');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-4 bg-gray-100">
      <Link className="text-blue-600 hover:text-blue-800 underline mb-4 inline-block" to="/admin">Back to Admin Dashboard</Link>

      <h2 className="text-2xl font-bold mb-4 text-gray-800">Users and Roles Management</h2>
      {message && <p className="text-sm font-medium text-red-600 mb-4">{message}</p>}

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2 text-gray-700">User List</h3>
        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-sm rounded-sm">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">ID</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Username</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Email</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Roles</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {users.map(user => (
                <tr key={user.id}>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{user.id}</td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{user.username}</td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{user.email}</td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{user.roles.map(role => role.name).join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h3 className="text-xl font-semibold mt-6 mb-2 text-gray-700">Assign Role to User</h3>
        <div className="space-y-2">
          <div>
            <label htmlFor="user-select" className="block text-sm font-medium text-gray-700">Select User:</label>
            <select
              id="user-select"
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select User</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>{user.username}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="role-select" className="block text-sm font-medium text-gray-700">Select Role:</label>
            <select
              id="role-select"
              value={selectedRoleId}
              onChange={(e) => setSelectedRoleId(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select Role</option>
              {roles.map(role => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>

          <button 
            onClick={handleAssignRole} 
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Assign Role
          </button>
        </div>
      </div>

      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-2 text-gray-700">Role Topics Management</h3>
        <div className="space-y-2">
          <div>
            <label htmlFor="role-select" className="block text-sm font-medium text-gray-700">Select Role:</label>
            <select
              id="role-select"
              value={selectedRoleId}
              onChange={(e) => {
                setSelectedRoleId(e.target.value);
                const role = roles.find(r => r.id === parseInt(e.target.value));
                setSelectedTopics(role ? role.topics.map(t => t.id) : []);
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select Role</option>
              {roles.map(role => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Select Topics:</label>
            <div className="mt-2 space-y-2">
              {topics.map(topic => (
                <label key={topic.id} className="inline-flex items-center mr-4">
                  <input
                    type="checkbox"
                    value={topic.id}
                    checked={selectedTopics.includes(topic.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTopics([...selectedTopics, topic.id]);
                      } else {
                        setSelectedTopics(selectedTopics.filter(id => id !== topic.id));
                      }
                    }}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <span className="ml-2">{topic.name}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="flex space-x-2">
            <button 
              onClick={handleUpdateRoleTopics} 
              disabled={loading}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              Update Role Topics
            </button>
            <button 
              onClick={handleResetRoleTopics} 
              disabled={loading}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50"
            >
              Reset Role Topics
            </button>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-xl font-semibold mb-2 text-gray-700">Create New Role</h3>
        <div className="space-y-2">
          <div>
            <label htmlFor="new-role-name" className="block text-sm font-medium text-gray-700">Role Name:</label>
            <input
              id="new-role-name"
              type="text"
              value={newRoleName}
              onChange={(e) => setNewRoleName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button 
            onClick={handleCreateRole} 
            disabled={loading}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Create Role
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserRoleManagement;