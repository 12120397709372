import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, X } from 'lucide-react';
import { ClickableArticleCard } from '../feed/components/ClickableArticleCard';
import config from '../config';
import DateNavigation from '../feed/components/DateNavigation';

const ArticleSelection = ({ topics, onProceed, contentType }) => {
  const [selectedTopic, setSelectedTopic] = useState('');
  const [articles, setArticles] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()).toISOString().split('T')[0]);
  const [loading, setLoading] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);

  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
  };

  useEffect(() => {
    if (selectedTopic) {
      setLoading(true);
      console.log("selected topic: ", selectedTopic)
      let topicUri = encodeURIComponent(selectedTopic).toLowerCase();
      const endpoint = `/get-articles/${topicUri}?date=${selectedDate}`;
  
      fetch(`${config.apiUrl}${endpoint}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((newData) => {
          setArticles(newData);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching articles:', error);
          setLoading(false);
        });
    }
  }, [selectedTopic, selectedDate]);

  const handleArticleClick = (article) => {
    setSelectedArticles(prevSelected => {
      if (prevSelected.some(a => a.id === article.id)) {
        return prevSelected.filter(a => a.id !== article.id);
      } else {
        return [...prevSelected, article];
      }
    });
  };

  const removeSelectedArticle = (articleId) => {
    setSelectedArticles(prevSelected => prevSelected.filter(a => a.id !== articleId));
  };

  const handlePrevDate = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    setSelectedDate(currentDate.toISOString().split('T')[0]);
  };

  const handleNextDate = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    setSelectedDate(currentDate.toISOString().split('T')[0]);
  };

  const isNextButtonDisabled = () => {
    return new Date(selectedDate).toISOString().split('T')[0] >= new Date(Date.now()).toISOString().split('T')[0];
  };

  return (
    <>
      <div className="max-w-2xl mx-auto px-4 py-8">

        <h2 className="text-3xl font-bold text-gray-900 mb-6">Select Your Topic</h2>
        <div className="relative">
          <select
            id="topic-select"
            value={selectedTopic}
            onChange={handleTopicChange}
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 ease-in-out"
          >
            <option value="">Select a topic</option>
            {topics.map((topic) => (
              <option key={topic.id} value={topic.name}>
                {topic.name}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <ChevronDownIcon className="h-5 w-5" />
          </div>
        </div>

        {selectedArticles.length > 0 && (
          <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Selected Articles</h3>
            <div className="flex flex-wrap gap-2 mb-6">
              {selectedArticles.map(article => (
                <div key={article.id} className="bg-gray-100 rounded-md p-2 flex items-center">
                  <span className="mr-2">{article.title}</span>
                  <button onClick={() => removeSelectedArticle(article.id)} className="text-red-500">
                    <X size={16} />
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={() => onProceed(selectedArticles)}
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
              Proceed
            </button>
          </div>
        )}
      </div>


      {selectedTopic && articles.length > 0 && (
        <div className="mt-8">
          <div className="main-content">
            {articles.map((article) => (
              <div className="article-container" key={article.id}>
                <ClickableArticleCard 
                  article={article} 
                  onClick={handleArticleClick}
                  isSelected={selectedArticles.some(a => a.id === article.id)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <DateNavigation 
        selectedDate={selectedDate} 
        onPrevDate={handlePrevDate} 
        onNextDate={handleNextDate} 
        isNextButtonDisabled={isNextButtonDisabled()} 
      />
    </>
  );
};

export default ArticleSelection;