import React, { useState } from 'react';
import filterIcon from '../../images/filter-outline.svg';
import '../../App.css';
import '../NewsArticleStyles.css';

const TopicHeader = ({ topicName, sortOptions, onSortChange }) => {
  const [showSortOptions, setShowSortOptions] = useState(false);

  return (
    <div className="topic-header-container">
      <h2 className="topic-headline text-xl font-bold">{topicName.toUpperCase()}</h2>
      <span>
        <button onClick={() => setShowSortOptions(prev => !prev)} className="filter-icon-button">
          <img src={filterIcon} alt="Filter" />
        </button>
        {showSortOptions && (
          <ul className="sort-options">
            {sortOptions.map((option) => (
              <li key={option.value} onClick={() => {
                onSortChange(option.value);
                setShowSortOptions(false);
              }}>
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </span>
    </div>
  );
};

export default TopicHeader;