import React, { createContext, useState, useEffect } from 'react';
import config from './config';

export const UserContext = createContext();

export const AuthProvider = ({ children }) => {    
    const [user, setUser] = useState(null);
    const [serviceAccessUpdated, setServiceAccessUpdated] = useState(false);

    useEffect(() => {
        const checkSession = async () => {
            try {
              const response = await fetch(`${config.apiUrl}/session-check`, {
                method: 'GET',
                credentials: 'include', // Necessary to include cookies
              });
              if (response.ok) {
                const data = await response.json();
                if (data.isAuthenticated) {
                  setUser(data.user); // Update the user state/context with the received user data
                } else {
                  setUser(null); // Clear user data if not authenticated
                }
              } else {
                console.error('Session check failed');
                setUser(null);
              }
            } catch (error) {
              console.error('Error during session check:', error);
            }
        };
          
        checkSession();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, serviceAccessUpdated, setServiceAccessUpdated }}>
            {children}
        </UserContext.Provider>
    );
};
