import React from 'react';
import { Link } from 'react-router-dom';
import './AdminDashboardStyles.css';
import topicsImage from '../../images/topics.webp';
import contentTypesImage from '../../images/content-types.webp';
import usersRolesImage from '../../images/user-roles.webp';
import couponsImage from '../../images/coupons.webp';
import promptImages from '../../images/prompt-testing.webp';

const AdminDashboard = () => {
  const adminSections = [
    { name: 'Manage Topics', path: '/admin/topics', thumbnail: topicsImage },
    { name: 'Manage Content Types', path: '/admin/content-types', thumbnail: contentTypesImage },
    { name: 'Manage Users and Roles', path: '/admin/users-roles', thumbnail: usersRolesImage },
    { name: 'Manage Coupons', path: '/admin/coupons', thumbnail: couponsImage },
    { name: 'Prompt Comparison', path: '/admin/prompt-comparison', thumbnail: promptImages },
    { name: 'Similarity', path: '/admin/similarity', thumbnail: promptImages }
  ];

  return (
    <div className="admin-dashboard-container">
      <div className="text-center mb-4">
        <Link className="underline profile-link" to="/profile">Go to User Profile</Link>
      </div>
      <div className="section-content">
        {adminSections.map((section, index) => (
          <div key={index} className={'materialCard'} style={{ backgroundImage: `url(${section.thumbnail})` }}>
            <Link to={section.path}>
              <div className="materialCard-content">
                <h3>{section.name}</h3>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;