import React from 'react';

function Contact() {
  const styles = {
    container: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '40px 20px', // Increased padding for a more spacious feel
      textAlign: 'center',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      height: '50vh', // This makes the container take up nearly the whole viewport height
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly', // Evenly space the contents vertically
      backdropFilter: 'blur(10px)'

    },
    header: {
      color: '#333',
      marginBottom: '20px' // More room below the header
    },
    subHeader: {
      color: '#555',
      marginBottom: '10px' // More room below subheaders
    },
    paragraph: {
      color: '#666',
      marginBottom: '20px' // More room below paragraphs
    },
    link: {
      color: '#1a0dab',
      textDecoration: 'none'
    }
  };

  return (
    <div style={styles.container}>

      <h1 style={styles.header}>Kontaktieren Sie uns</h1>

      <div>
        <h2 style={styles.subHeader}>E-Mail</h2>
        <p style={styles.paragraph}>
            <a href="mailto:office@artecont.at" style={styles.link}>
            office@artecont.at
            </a>
        </p>
      </div>

    </div>
  );
}

export default Contact;
