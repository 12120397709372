import React from 'react';
import { Pie } from 'react-chartjs-2';
import '../../App.css';
import '../NewsArticleStyles.css';

const ArticleCharts = ({ article }) => {
  const pieData_1 = {
    labels: ['Allgemein'],
    datasets: [
      {
        data: [article.score_general, 100 - article.score_general],
        backgroundColor: ['#030027', 'rgba(0, 0, 0, 0.0)'],
      },
    ],
  };

  const pieData_2 = {
    labels: ['Disruption'],
    datasets: [
      {
        data: [article.score_disruptiveness, 100 - article.score_disruptiveness],
        backgroundColor: ['#151e3f', 'rgba(0, 0, 0, 0.0)'],
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 11,
          },
          boxWidth: 14,
          padding: 8,
        },
      },
      title: {
        display: false,
      },
    },
    interaction: {
      mode: 'none',
    },
  };

  const pieChartStyle = {
    display: 'block',
    width: '100%',
  };

  return (
    <div className="column-bg graphContainer">
      <div className="pie-chart">
        <Pie data={pieData_1} options={pieOptions} style={pieChartStyle} />
      </div>
      <div className="pie-chart">
        <Pie data={pieData_2} options={pieOptions} style={pieChartStyle} />
      </div>
    </div>
  );
};

export default ArticleCharts;