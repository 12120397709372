import React from 'react';
import '../../App.css';
import '../NewsArticleStyles.css';

const DateNavigation = ({ selectedDate, onPrevDate, onNextDate, isNextButtonDisabled }) => {
  return (
    <div className="date-navigation-container">
      <button className="date-nav-button" onClick={onPrevDate}>Voriger Tag</button>
      <span className="date-display">{selectedDate}</span>
      <button className="date-nav-button" onClick={onNextDate} disabled={isNextButtonDisabled}>Nächster Tag</button>
    </div>
  );
};

export default DateNavigation;