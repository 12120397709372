import React from 'react';
import './LandingPage.css';
import config from '../config';
import webcrawler from '../images/news_hub.png';
import zusammenfassung from '../images/text_summary.png';
import bewertung from '../images/text_analysis.png';
import cockpit from '../images/social_media_hub.png';

import zeitersparnis from '../images/zeitersparnis.webp';
import skalierbarkeit from '../images/skalierbarkeit.webp';
import kosteneinsparung from '../images/kosteneinsparung.webp';
import wettbewerbsvorteil from '../images/wettbewerbsvorteil.webp';
import inhaltsquali from '../images/inhaltsquali.webp';
import produktivi from '../images/produktivi.webp';
import engagement from '../images/engagement.webp';
import benutzerfreundlichkeit from '../images/benutzerfreundlichkeit.webp';


const LandingPage = () => {
    return (
        <div className="landing-page">

            <main>
                <section className="hero">
                    <h1 className="text-2xl font-bold">Quality Content auf dem nächsten Level</h1>

                    <p>Content Aufarbeitung für Ihre Community mittels KI-unterstützem Workflow</p>
                    <p>Ultimatives Empowerment für Content Creators</p>
                    <div className="video-container">
                        <video width="100%" height="auto" controls>
                            <source src={`${config.apiUrl}/static/IntelliScout.mp4`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <br></br><br></br>

                    
                    <a href="mailto:office@artecont.at" className="cta-button">Beta Zugang anfordern</a>

                </section>

                <section className="features">
                    <h2 className="font-bold">Features</h2>
                    <div className="feature-container">
                        <div className="feature-box">
                            <img src={webcrawler} alt="Web-Crawling-Symbol" />
                            <h3 className="font-bold">Web-Crawling</h3>
                            <p>Automatisches Erfassen und Übersetzen von relevantem Content aus dem gesamten Web</p>
                        </div>
                        <div className="feature-box">
                            <img src={zusammenfassung} alt="Zusammenfassungssymbol" />
                            <h3 className="font-bold">Zusammenfassung</h3>
                            <p>Erfassen Sie schnell die Kernpunkte mit prägnanten Zusammenfassungen</p>
                        </div>
                        <div className="feature-box">
                            <img src={bewertung} alt="Bewertungssymbol" />
                            <h3 className="font-bold">Evaluierung</h3>
                            <p>KI-gestütze Evaluierung nach Relevanz und Innovation</p>
                        </div>
                        <div className="feature-box">
                            <img src={cockpit} alt="Content Cockpit" />
                            <h3 className="font-bold">Content Cockpit</h3>
                            <p>Ein übersichtliches KI-gestütztes Navigationstool um aus der überbordernden Informationsflut relevante Inhalte zu teilen</p>
                        </div>
                    </div>
                </section>

                <section className="benefits">
                    <h2 className="font-bold">Benefits</h2>
                    <div className="benefits-container">
                        <div className="feature-box">
                            <img src={zeitersparnis} alt="Zeitersparnis" />
                            <h3 className="font-bold">Zeitersparnis</h3>
                            <p>Konzentrieren Sie sich auf die wichtigsten Informationen</p>
                        </div>
                        <div className="feature-box">
                            <img src={skalierbarkeit} alt="Skalierbarkeit" />
                            <h3 className="font-bold">Skalierbarkeit</h3>
                            <p>Skalieren Sie Ihre Content-Erstellung, ohne zusätzliche Ressourcen zu benötigen</p>
                        </div>
                        <div className="feature-box">
                            <img src={kosteneinsparung} alt="Kosteneinsparungen" />
                            <h3 className="font-bold">Kosteneinsparungen</h3>
                            <p>Nutzen Sie eine erschwingliche Lösung, die Ihnen hilft, Ihr Content-Marketing optimal zu skalieren</p>
                        </div>
                        <div className="feature-box">
                            <img src={wettbewerbsvorteil} alt="Wettbewerbsvorteil" />
                            <h3 className="font-bold">Wettbewerbsvorteil</h3>
                            <p>Aktuelles, zielgruppenoptimiertes Veröffentlichen relevanter Inhalte</p>
                        </div>
                        <div className="feature-box">
                            <img src={inhaltsquali} alt="Verbesserte Inhaltsqualität" />
                            <h3 className="font-bold">Quality Content</h3>
                            <p>Erstellen Sie täglich aktuelle und hochwertige Inhalte in hochwertigster Übersetzung</p>
                        </div>
                        <div className="feature-box">
                            <img src={produktivi} alt="Gesteigerte Produktivität" />
                            <h3 className="font-bold">Gesteigerte Produktivität</h3>
                            <p>Optimieren Sie Ihren Content-Creation-Prozess und konzentrieren Sie sich auf strategische Aufgaben</p>
                        </div>
                        <div className="feature-box">
                            <img src={engagement} alt="Erhöhtes Engagement" />
                            <h3 className="font-bold">Erhöhtes Engagement</h3>
                            <p>Fördern Sie Interaktionen, Shares und Diskussionen durch hochwertige Beiträge</p>
                        </div>
                        <div className="feature-box">
                            <img src={benutzerfreundlichkeit} alt="Benutzerfreundlichkeit" />
                            <h3 className="font-bold">Benutzer- freundlichkeit</h3>
                            <p>Profitieren Sie von einer intuitiven und benutzerfreundlichen Oberfläche</p>
                        </div>
                    </div>
                </section>

                <section className="cta">
                    <a href="mailto:office@artecont.at" className="cta-button">Beta Zugang anfordern</a>
                    <br></br>
                    <br></br>
                    <br></br>

                    <p>Testen Sie unsere kostenlose Testversion</p>
                </section>
            </main>

        </div>
    );
};

export default LandingPage;