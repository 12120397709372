import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const ContentTypeManagement = () => {
  const [contentTypes, setContentTypes] = useState([]);
  const [contentTypeName, setContentTypeName] = useState('');
  const [contentTypeThumbnailUrl, setContentTypeThumbnailUrl] = useState('');
  const [message, setMessage] = useState('');
  const [contentTypeEditState, setContentTypeEditState] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedbackMessages, setFeedbackMessages] = useState({});

  const fetchContentTypes = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/content/get-all`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch content types');
      }
      const data = await response.json();
      data.sort((a, b) => a.id - b.id);
      setContentTypes(data);
    } catch (error) {
      console.error('Error fetching content types:', error);
      setMessage('Failed to fetch content types');
    }
  };

  useEffect(() => {
    fetchContentTypes();
  }, []);

  const handleContentTypeSubmit = async (e) => {
    e.preventDefault();
    if (!contentTypeName) {
      setMessage('Please fill in the content type name');
      return;
    }

    setLoading(true);
    const response = await fetch(`${config.apiUrl}/content/create`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: contentTypeName, thumbnail_url: contentTypeThumbnailUrl, active: true }),
    });

    setLoading(false);
    if (response.ok) {
      setMessage('Content type created successfully');
      setContentTypeName('');
      setContentTypeThumbnailUrl('');
      fetchContentTypes();
    } else {
      const errorMessage = await response.text();
      setMessage(`Failed to create content type: ${errorMessage}`);
    }
  };

  const handleContentTypeEditChange = (contentTypeId, field, value) => {
    setContentTypeEditState(prev => ({
      ...prev,
      [contentTypeId]: {
        ...prev[contentTypeId],
        [field]: value,
      },
    }));
  };

  const handleContentTypeUpdate = async (contentTypeId) => {
    setLoading(true);
    const contentTypeToUpdate = contentTypeEditState[contentTypeId];
    const response = await fetch(`${config.apiUrl}/content/update`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: contentTypeId, ...contentTypeToUpdate }),
    });

    setLoading(false);
    if (response.ok) {
      setFeedbackMessages(prev => ({ ...prev, [contentTypeId]: 'Update successful' }));
      setContentTypeEditState(prev => {
        const newState = { ...prev };
        delete newState[contentTypeId];
        return newState;
      });
      fetchContentTypes();
    } else {
      const errorMessage = await response.text();
      setFeedbackMessages(prev => ({ ...prev, [contentTypeId]: `Failed to update: ${errorMessage}` }));
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 bg-gray-100">
      <Link className="text-blue-600 hover:text-blue-800 underline mb-4 inline-block" to="/admin">Back to Admin Dashboard</Link>

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Create a New Content Type</h2>
        {message && <p className="text-sm font-medium text-red-600 mb-4">{message}</p>}
        <form onSubmit={handleContentTypeSubmit} className="space-y-4">
          <div>
            <label htmlFor="contentTypeName" className="block text-sm font-medium text-gray-700">Content Type Name:</label>
            <input
              id="contentTypeName"
              type="text"
              value={contentTypeName}
              onChange={(e) => setContentTypeName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="contentTypeThumbnailUrl" className="block text-sm font-medium text-gray-700">Thumbnail URL:</label>
            <input
              id="contentTypeThumbnailUrl"
              type="text"
              value={contentTypeThumbnailUrl}
              onChange={(e) => setContentTypeThumbnailUrl(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button 
            type="submit" 
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Create Content Type
          </button>
        </form>
      </div>

      <h2 className="text-2xl font-bold mb-4 text-gray-800">Manage Content Types</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Name</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Thumbnail</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Active</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {contentTypes.map((contentType) => (
              <tr key={contentType.id}>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{contentType.id}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={contentTypeEditState[contentType.id]?.name || contentType.name}
                    onChange={(e) => handleContentTypeEditChange(contentType.id, 'name', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={contentTypeEditState[contentType.id]?.thumbnail_url || contentType.thumbnail_url}
                    onChange={(e) => handleContentTypeEditChange(contentType.id, 'thumbnail_url', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={contentTypeEditState[contentType.id]?.active ?? contentType.active}
                    onChange={(e) => handleContentTypeEditChange(contentType.id, 'active', e.target.checked)}
                  />
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                  <button 
                    onClick={() => handleContentTypeUpdate(contentType.id)} 
                    disabled={loading}
                    className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Update
                  </button>
                  <span className="ml-2 text-xs text-gray-600">{feedbackMessages[contentType.id]}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContentTypeManagement;